<template>
  <div>
    <v-app-bar app color="sky-grey darken-1" height="90" class="px-6">
      <img src="/assets/L2Sky-logo-white.png" contain height="70" width="161" />
    </v-app-bar>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Full",
};
</script>